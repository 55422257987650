import { themeIcons } from "config/theme";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Modal, ModalBody, Spinner } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import VIcon from "components/custom/Icon";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";

import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, SessionKey, _MAIN_WALLET_ID, WebUrl } from "util/Constant";
import { numberWithCurrencyFormat, stringIsNullOrEmpty, isObjectEmpty } from "../../util/Util";
import ApiEngine from "util/ApiEngine";
import LoadingPlaceholder from "./LoadingPlaceholder";
import Loading from "./Loading";
import InfoBox from "../../components/custom/InfoBox";

import {
  showMessage,
  showResponseMessage,
  setBusy,
  setIdle,
  setGameSearchText,
  setGameQuery,
  setPageSize,
  setY,
  bindMember,
  setGameLobbyModal,
  bindCompanyIcon
} from "redux/AppAction.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { map, debounce, find, filter, upperCase } from "lodash";
import { heartBeat as heartBeatDispatch } from "redux/AuthAction";

const GameLobbyModal = (props) => {
  var { t } = useTranslation();
  const _history = useHistory();
  var _dispatch = useDispatch();
  const memberData = useSelector((state) => state.appState.member);
  const _USER_DATA = useSelector((s) => s.authState.userData);
  let usernameRef = useRef();
  let passwordRef = useRef();

  const showLobby = useSelector((state) => state.appState.gameLobbyModal);
  const walletsDetails = useSelector((state) => state.appState.walletsDetails);

  const [walletList, setWalletList] = useState([]);
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [clubFee, setClubFee] = useState(0);
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [masterProductContent, setMasterProductContent] = useState("");
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [mpData, setMpData] = useState([]);
  const [isApp, setIsApp] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [appDeepLink, setAppDeepLink] = useState("");
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [toLobbyBtn, setToLobbyBtn] = useState(false);
  const [showAppCred, setShowAppCred] = useState(false);
  const [selectedGameIsSeamless, setSelectedGameIsSeamless] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [masterProductId, setMasterProductId] = useState(0);
  const [selectedGameName, setSelectedGameName] = useState("");
  const [popupBannerImage, setPopupBannerImage] = useState("");

  const [isDisabled, setDisabled] = useState(false);
  const [productCommRate, setProductCommRate] = useState([]);
  const [productGameId, setProductGameId] = useState(0);
  const [appUsername, setAppUsername] = useState("");
  const [appPassword, setAppPassword] = useState("");
  const [isNewWindow, setIsNewWindow] = useState(false);
  const [rolloverBalance, setRolloverBalance] = useState("");
  const [rolloverTarget, setRolloverTarget] = useState("");
  const [mincoinTarget, setMincoinTarget] = useState("");
  const [maxWithdraw, setMaxWithdraw] = useState("");
  const [promoTitle, setPromoTitle] = useState("");
  const [promoSlug, setPromoSlug] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBonusWallet, setIsBonusWallet] = useState(false);
  const [isWalletLoaded, setIsWalletLoaded] = useState(false);
  const [showRestoreAccBlanceConfirmation, setShowRestoreAccBlanceConfirmation] = useState(false);
  var { heartBeat } = useSelector((state) => state.authState);
  const companyIcons = useSelector((state) => state.appState.companyIcons);
  const [alertIcon, setAlertIcon] = useState();
  const [defaultGameBanner, setDefaultGameBanner] = useState();
  const [seconds, setSeconds] = useState(-1);
  const isBusy = useSelector(
    (state) => state.appState.isBusy
  );
  const [isValidForPromotion, setIsValidForPromotion] = useState(false);
  const [countdown, setCountdown] = useState(5); // Start countdown at 5 seconds
  const [intervalId, setIntervalId] = useState(null);
  const [promoAlert, setPromoAlert] = useState(false);
  var totalPromoClaimable = useSelector((state) =>
	state.authState.heartBeat ? state.authState.userData.totalPromoClaimable : 1
  );


  useEffect(() => {
    //bindCompanyIcon(_dispatch);
  }, []);

  useEffect(() => {
    if (companyIcons.length > 0) {
      getCompanyIcon();
    }
  }, [companyIcons]);

  useEffect(() => {
    if (showLobby) {
      getRolloverProgress();
      CheckWallet(props.masterProductId);
      setProductGameId(props.productGameId)
      setSelectedGameIsSeamless(props.selectedGameIsSeamless)
      setMasterProductId(props.masterProductId)
      setSelectedGameName(props.selectedGameName)
      setPopupBannerImage(props.popupBannerImage)

      startGame(props.masterProductId)

    }

  }, [showLobby])

  const startGame = async (masterProductId) => {

    setIsLoading(true)
    if (
      props.selectedGameName == "Pussy888" ||
      props.selectedGameName == "Mega888" ||
      props.selectedGameName == "Evo888"
    ) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_APP_LOGIN_CRED + "?productId=" + masterProductId
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setShowAppCred(true);
        setAppUsername(responseJson[ApiKey._API_DATA_KEY].username);
        setAppPassword(responseJson[ApiKey._API_DATA_KEY].password);
      }
    }
    // setProductGameId(productGameId);
    var isNewWindowJson = await ApiEngine(
      ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + props.productGameId
    );
    setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
    // setShowPregamePopup(true);
    getProductComRate(props.selectedGameName);
    getRolloverProgress(props.productGameId);
    setIsLoading(false)

  }

  const RefreshBalanceF = async () => {
    // setMasterProductId(masterProductId);
    // _dispatch(setBusy());
    setBalanceLoading(true);

    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
      "?masterProductId=" +
      props.masterProductId
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setWalletList(responseJson[ApiKey._API_DATA_KEY]);

      let tempFromOptions = [];
      let tempToOptions = [];

      responseJson[ApiKey._API_DATA_KEY].map((product) => {
        let option = {
          label: product.productName,
          value: product.productId,
          amount: parseFloat(product.balance),
        };
        if (product.productId === _MAIN_WALLET_ID) {
          tempFromOptions.push(option);
        } else if (product.productId === props.masterProductId) {
          setBonus(product.bonus);
          setCommission(product.commission);
          if (product.productName === "D2 Poker") {
            setClubFee(product.clubFee);
          }
          setGameWalletBalanceBeforeTransfer(product.balance);
          if (product.masterProductContent !== null) {
            setMasterProductContent(product.masterProductContent);
          } else {
            setMasterProductContent("");
          }
          tempToOptions.push(option);
        }
      });
      setFromOptions(tempFromOptions);
      setToOptions(tempToOptions);
      // _dispatch(setIdle());
      setBalanceLoading(false);

      setDisabled(true);
      setTimeout(() => setDisabled(false), 5000);
    }
  }

  const getProductComRate = async (selectedGameName) => {
    let apiUrl = ApiUrl._API_PRODUCT_COMM_RATE;
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY].filter(
        (x) => x.productName === selectedGameName
      );

      if (data.length > 0) {
        setProductCommRate(data[0]);
      }
    }
  }
  const getRolloverProgress = async () => {
    if (props.masterProductId != "0" && !stringIsNullOrEmpty(masterProductId)) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_DEPOSIT_PROMOTION_ROLLOVER_PROGRESS +
        "?masterProductId=" +
        props.masterProductId
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY] != null) {
          if (responseJson[ApiKey._API_DATA_KEY].data != null) {
            setRolloverBalance(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverBalance"]
            );
            setRolloverTarget(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverTarget"]
            );
            setMincoinTarget(
              responseJson[ApiKey._API_DATA_KEY].data["mincoinTarget"]
            );
            setMaxWithdraw(
              responseJson[ApiKey._API_DATA_KEY].data["maxWithdraw"]
            );
            setPromoTitle(
              responseJson[ApiKey._API_DATA_KEY].data["title"]
            );
            setPromoSlug(
              responseJson[ApiKey._API_DATA_KEY].data["slug"]
            );
          }
        }
      }
    }
  }

  const copyText = async (textType) => {
    if (textType === "username") {
      usernameRef.current.select();
    } else if (textType === "password") {
      passwordRef.current.select();
    }
    document.execCommand("copy");
    //setShowCopyAlert(true);
    //setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
  }

  async function getRestore() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(ApiUrl._API_RESTORE_ALL_WALLET);

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());
  }

  async function CheckWallet(id) {
    setIsWalletLoaded(false);

    if(!stringIsNullOrEmpty(id)) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_CHECK_WALLET +
        "?id=" +
        id
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setIsBonusWallet(responseJson[ApiKey._API_DATA_KEY]);
        setIsWalletLoaded(true);
      }
    }
  }

  const confirmRestore = () => {
    setShowRestoreAccBlanceConfirmation(true)

  }

  const getRestoreAccountBlance = async () => {
    setShowRestoreAccBlanceConfirmation(false)

    _dispatch(setBusy());

    // setConfirmLoading(true)
    let responseJson = await ApiEngine.get(ApiUrl._API_RESTORE_MAIN_WALLET);

    _dispatch(heartBeatDispatch());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      CheckWallet(props.masterProductId);
      getRolloverProgress();
    }

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    // setShowRestoreAccBlanceConfirmation(false)
    // setConfirmLoading(false)

    _dispatch(setIdle());
  }


    async function startGame2(gameId) {
        var breadcrumbData = [{
            pageName: props.category,
            location: _history.location.pathname + _history.location.search,
            state: _history.location.state
        }];

        var path = WebUrl._URL_IFRAME_GAME_PAGE + "?gameId=" + gameId;
        _history.push(path, {
            breadcrumbData: breadcrumbData
        });

    _dispatch(setGameLobbyModal(false))
    setToLobbyBtn(false);
    setShowAppCred(false);
    //window.open(
    //  ApiUrl._API_START +
    //  "?gameId=" +
    //  gameId + "&device=" + (window.innerWidth <= 768 ? "m" : "d")
    //);
  }

  async function getCompanyIcon() {
    _dispatch(setBusy());
    const alert = find(companyIcons, { iconCategory: "alert" });
    if (alert) {
      setAlertIcon(alert.iconImage);
    }

    const defaultGameBanner = find(companyIcons, { iconCategory: "default_game_banner" });
    if (defaultGameBanner) {
      setDefaultGameBanner(defaultGameBanner.iconImage);
    }
    _dispatch(setIdle());
  }

  useEffect(() => {
    if (seconds === 0) return;

    const interval = setInterval(() => {
      if (rolloverBalance !== "" || rolloverTarget !== "") {
        getRolloverProgress(props.productGameId)
      }
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 15000);

    return () => clearInterval(interval);
  }, [seconds]);

  async function setTransactionValidForPromo() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(
      ApiUrl._API_UPDATE_TRANSACTION_VALID_FOR_PROMO
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setIsValidForPromotion(false);
    }
    _dispatch(setIdle());
  }

  function countDownAlert() {
    // Ensure there's no ongoing countdown
    if (intervalId) clearInterval(intervalId);

    // Set up the countdown interval
    const id = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          clearInterval(id);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    setIntervalId(id);

    return () => clearInterval(id); // Clean up on component unmount
  }

  return (
    <>
      {promoAlert && (
        <div className="sweet-alert-container t3-sweet-alert-container">
          <SweetAlert
            customIcon= {(<img
              className="m-auto"
              src={alertIcon}
              height="90px"
              width="90px"
              alt="Custom Icon"
            />)}
            cancelBtnText={t("CANCEL")}
            confirmBtnText={t("CONTINUE")}
            icon
            // onConfirm={() => {
            //   getRestoreAccountBlance();
            // }}
            onCancel={() => {
              setPromoAlert(false);
              setCountdown(5);
            }}
            custom
            showCancel
            reverseButtons={true}
            cancelBtnBsStyle="danger"
            customButtons={
              <React.Fragment>
                <div className="sweetalert-custom-buttons">
                  <button
                    className={"btn btn-lg " + (countdown != 0 ? "btn-danger" : "btn-primary")}
                    disabled={countdown != 0 ? true : false}
                    onClick={() => {
                      setTransactionValidForPromo();
                      setPromoAlert(false);
                      setCountdown(5);
                      startGame2(
                        props.productGameId
                      );
                      // console.log('confirm proceed')
                    }}
                  >
                    {t("CONTINUE_ANYWAY")} {countdown != 0 ? "(" + countdown + ")" : ""}
                  </button>
                  <a
                    onClick={() => (setPromoAlert(false), _dispatch(setGameLobbyModal(false)), _history.push(WebUrl._URL_PROMOTION))}
                    className="btn btn-primary btn-lg"
                  >
                    {t("GO_TO_PROMOTIONS")}
                  </a>
                </div>

              </React.Fragment>
            }
          >
            <span className="sweet-alert-custom-text">
              <div dangerouslySetInnerHTML={{ __html: t("PLEASE_CLAIM_PROMO_BEFORE_ENTER_GAME_HEADING").replace('\n', '<br/>') }} style={{ marginBottom: "20px", fontSize: "14px" }} />
        <     InfoBox style={{ maxWidth: "100%", margin: "0 16px", marginBottom: "0px" }} type="danger" content={t("PLEASE_CLAIM_PROMO_BEFORE_ENTER_GAME_WARNING")} />
            </span>
          </SweetAlert>
        </div>
      )}
      {showRestoreAccBlanceConfirmation && (
        <div className="sweet-alert-container t3-sweet-alert-container">

          <SweetAlert
            cancelBtnText={t("CANCEL")}
            confirmBtnText={t("CONTINUE")}
            icon
            onConfirm={() => {
              getRestoreAccountBlance();
            }}
            onCancel={() => {
              setShowRestoreAccBlanceConfirmation(false);
            }}
            custom
            showCancel
            reverseButtons={true}
            cancelBtnBsStyle="danger"
            customIcon={alertIcon}

            customButtons={
              <React.Fragment>
                <button className="btn btn-lg btn-primary" onClick={() => getRestoreAccountBlance()}>Confirm </button>

                <button className="btn btn-lg btn-danger" onClick={() => {
                  setShowRestoreAccBlanceConfirmation(false);
                }}>Cancel</button>

              </React.Fragment>
            }
          >
            <span className="sweet-alert-custom-text">
              {t("RESTORE_WALLETS")}
            </span>
          </SweetAlert>
        </div>
      )}
      <Modal
        id="t3-custom-modal-withdraw"
        fade={false}
        // contentClassName="modal-brand modal-bottom reload-bank-modal withdraw-accounts-modal"
        isOpen={showLobby}
        toggle={() => {
          _dispatch(setGameLobbyModal(false))
          // setShowPregamePopup(false);
          setToLobbyBtn(false);
          // setShowAppCred(false);
        }}
      // centered
      >
        <ModalBody>


          <div className="t3-custom-modal-withdraw-container t3-modal-bg-dark t3-default-lobby">
            <div className="t3-cms-header">
              <div className="first">
                <div>{props.selectedGameName}</div>
              </div>
              <div className="second">
                <div
                  className="t3-close-custom-modal"
                  onClick={() => {
                    _dispatch(setGameLobbyModal(false))
                    setToLobbyBtn(false);
                    setShowAppCred(false);
                  }}
                >
                  <VIcon icon={themeIcons.close} className="vicon" />
                </div>
              </div>
            </div>
            <div className="t3-withdraw-modal-body pb-5">
              <div id="game-lobby-new">
                {isLoading ?
                  (<Loading />) :
                  (<>
                    <div className="">
                      <div className="gamelobby-banner">
                        {!stringIsNullOrEmpty(props.popupBannerImage) ? (
                          <img
                            src={props.popupBannerImage}
                            className="img-100 border-radius-10"
                            alt="banner"
                          />
                        ) : (
                          <img
                            src={defaultGameBanner}
                            className="img-100 border-radius-10"
                            alt="banner"
                          />
                        )}
                      </div>
                    </div>
                    {!props.selectedGameIsSeamless && isWalletLoaded && (
                      <>
                        <div className="default-lobby-player-detail mt-3">
                          {/*{isBonusWallet && */}
                          {/*    <div className="bg-side-decor">*/}
                          {/*    </div>  */}
                          {/*}*/}
                          <div className={`home-balance-com-box  ${isBonusWallet ? 'bonus-home-balance-com-box' : ''}`}>
                            {!isBonusWallet && <>
                              { /* WALLET */}
                              <div
                                className="hbc-box"
                              >
                                <div className="first">
                                  <div className="hbc-label font12 text-lgray4">
                                    {t("MAIN_WALLET")}
                                  </div>
                                  <div className="hbc-balance font14 f-weight-500">
                                    {numberWithCurrencyFormat(
                                      walletsDetails.currentWalletBalance,
                                      2,
                                      true
                                    )}
                                  </div>
                                </div>
                                <div className="second-wrapper">
                                  <div className="second">
                                    <VIcon
                                      icon={themeIcons.refresh}
                                      className="theme-icon-size-15"
                                      onClick={() => {
                                        getRestore();
                                      }}
                                      disabled={isDisabled}
                                    />
                                  </div>
                                </div>
                              </div>
                              { /* BASED REBATE */}
                              <div className="hbc-box">
                                <div className="first">
                                  <div className="hbc-label font12 text-lgray4">
                                    {t("GUARANTEED_REBATE")}
                                  </div>
                                  <div className="hbc-balance font14 f-weight-500">
                                    {!isObjectEmpty(productCommRate)
                                      ? productCommRate.playerComm.toFixed(2)
                                      : "0.00"}
                                    %
                                  </div>
                                </div>
                                <div className="second-wrapper">
                                  <div className="second">
                                    <VIcon
                                      icon={themeIcons.dashboardCommission}
                                      className="theme-icon-size-20"
                                    />
                                  </div>
                                </div>
                              </div>
                              { /* ADDITIONAL REBATE */}
                              <div className="hbc-box">
                                <div className="first">
                                  <div className="hbc-label font12 text-lgray4">
                                    {t("MEMBERSHIP_REBATE")}
                                  </div>
                                  <div className="hbc-balance font14 f-weight-500">
                                    {productCommRate.categoryName == "Slots"
                                      ? memberData.membership?.rebateSlot.toFixed(2)
                                      : productCommRate.categoryName == "Sports"
                                        ? memberData.membership?.rebateSport.toFixed(2)
                                        : memberData.membership?.rebateCasino.toFixed(2)}
                                    %
                                  </div>
                                </div>
                                <div className="second-wrapper">
                                  <div className="second">
                                    {memberData.membership?.membership > 1 ? (
                                      <VIcon
                                        icon={themeIcons.dashboardCommission}
                                        className="theme-icon-size-20"
                                      />
                                    ) : (
                                      <VIcon
                                        icon={themeIcons.lockClosed}
                                        className="theme-icon-size-20"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>}

                            {isBonusWallet && <>

                              { /* BASED REBATE */}
                              <div className="hbc-box">
                                <div className="first">
                                  <div className="hbc-label font12 text-lgray4">
                                    {t("PROMOTION")}
                                  </div>
                                  <div className="hbc-balance font14 f-weight-500">
                                    {promoTitle}
                                  </div>
                                </div>
                                <div className="second-wrapper">
                                  <div className="second">
                                    <VIcon
                                      icon={themeIcons.dashboardCommission}
                                      className="theme-icon-size-20"
                                    />
                                  </div>
                                </div>
                              </div>

                              { /* WALLET */}
                              <div
                                className="hbc-box"
                              >
                                <div className="first">
                                  <div className="hbc-label font12 text-lgray4">
                                    {t("COINS")}
                                  </div>
                                  <div className="hbc-balance font14 f-weight-500">
                                    {numberWithCurrencyFormat(
                                      walletsDetails.bonusWalletBalance,
                                      2,
                                      true
                                    )}
                                  </div>
                                </div>
                                <div className="second-wrapper">
                                  <div className="second">
                                    <VIcon
                                      icon={themeIcons.refresh}
                                      className="theme-icon-size-15"
                                      onClick={() => {
                                        getRestore();
                                      }}
                                      disabled={isDisabled}
                                    />
                                  </div>
                                </div>
                              </div>
                              {isBonusWallet && rolloverBalance !== "" && rolloverTarget !== "" && (
                                <div
                                  className="hbc-box"
                                >
                                  <div className="first" style={{ width: "100%" }}>
                                    <div className="hbc-label font12 text-lgray4">
                                      {t("ROLLOVER")}
                                    </div>
                                    <div className="progress-bar-box-deposit-promotion" style={{ marginLeft: "0px" }} >
                                      <span
                                        className="progress-identifier"
                                        style={{
                                          width:
                                            rolloverTarget == rolloverBalance
                                              ? "0%"
                                              : ((rolloverTarget - rolloverBalance) /
                                                rolloverTarget) *
                                              100 +
                                              "%",
                                        }}
                                      ></span>

                                    </div>
                                    <div className="font12 text-lgrey4">
                                      {numberWithCurrencyFormat(
                                        parseFloat(rolloverTarget - rolloverBalance),
                                        2,
                                        true
                                      )}{" "}
                                      /{" "}
                                      {numberWithCurrencyFormat(
                                        parseFloat(rolloverTarget),
                                        2,
                                        true
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {isBonusWallet && rolloverBalance !== "" && rolloverTarget !== "" && (
                                <div
                                  className="hbc-box"
                                >
                                  <div className="first" style={{ width: "100%" }}>
                                    <div className="hbc-label font12 text-lgray4">
                                      {t("TARGET")}
                                    </div>
                                    <div className="progress-bar-box-deposit-promotion" style={{ marginLeft: "0px" }} >
                                      <span
                                        className="progress-identifier"
                                        style={{
                                          width:
                                            mincoinTarget <= walletsDetails.bonusWalletBalance
                                              ? "100%"
                                              : ((walletsDetails.bonusWalletBalance) /
                                                mincoinTarget) *
                                              100 +
                                              "%",
                                        }}
                                      ></span>

                                    </div>
                                    <div className="font12 text-lgrey4"
                                    >{numberWithCurrencyFormat(
                                      parseFloat(walletsDetails.bonusWalletBalance > mincoinTarget ? mincoinTarget : walletsDetails.bonusWalletBalance),
                                      2,
                                      true
                                    )}{" "}
                                      /{" "}
                                      {numberWithCurrencyFormat(
                                        parseFloat(mincoinTarget),
                                        2,
                                        true
                                      )}</div>
                                  </div>
                                </div>
                              )}
                            </>}

                            {/*            {isBonusWallet && <>   */}
                            {/*                { /* COINS */}
                            {/*                <div*/}
                            {/*                    className="hbc-box"*/}
                            {/*                >*/}
                            {/*                    <div className="first">*/}
                            {/*                        <div*/}
                            {/*                            className="hbc-label"*/}
                            {/*                            style={{ whiteSpace: "nowrap" }}*/}
                            {/*                        >*/}
                            {/*                            {t("BONUS_WALLET")}*/}
                            {/*                        </div>*/}
                            {/*                        <div className="hbc-balance font14 f-weight-500">*/}
                            {/*                            {numberWithCurrencyFormat(*/}
                            {/*                            walletsDetails.bonusWalletBalance,*/}
                            {/*                            2,*/}
                            {/*                            true*/}
                            {/*                            )}*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="second-wrapper">*/}
                            {/*                    <div className="second">*/}
                            {/*                        <VIcon*/}
                            {/*                        icon={themeIcons.refresh}*/}
                            {/*                        className="theme-icon-size-15"*/}
                            {/*                        onClick={() => {*/}
                            {/*                            getRestore();*/}
                            {/*                        }}*/}
                            {/*                        disabled={isDisabled}*/}
                            {/*                        />*/}
                            {/*                    </div>*/}
                            {/*                    </div>*/}
                            {/*                </div> */}
                            {/*                { /* More Info */}
                            {/*                <div className="center-orna">*/}
                            {/*                    <div className="back-decor"></div>*/}
                            {/*                    <div className="main-decor">*/}
                            {/*                    </div>*/}
                            {/*                    <div className="wallet-notice">*/}
                            {/*                        <a href={"/promo/"+promoSlug} target="_blank" rel="noopener noreferrer" className="wallet-container">*/}
                            {/*                            {promoTitle} */}
                            {/*                        </a>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                { /* BONUS ROLLOVER */}
                            {/*                {isBonusWallet && rolloverBalance !== "" && rolloverTarget !== "" && (*/}
                            {/*                    <div className="rollover-balance-box">*/}
                            {/*                        <div className="d-flex">*/}
                            {/*                            <div*/}
                            {/*                                className="mb-1"*/}
                            {/*                                style={{ margineTop: "30px" }}*/}
                            {/*                            >*/}
                            {/*                                <span className="hbc-label">*/}
                            {/*                                {t("ROLLOVER")}*/}
                            {/*                                </span>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="progress-bar-box-deposit-promotion">*/}
                            {/*                                <span*/}
                            {/*                                className="progress-identifier"*/}
                            {/*                                    style={{*/}
                            {/*                                        width:*/}
                            {/*                                        rolloverTarget == rolloverBalance*/}
                            {/*                                            ? "0%"*/}
                            {/*                                            : ((rolloverTarget - rolloverBalance) /*/}
                            {/*                                                rolloverTarget) **/}
                            {/*                                                100 +*/}
                            {/*                                            "%",*/}
                            {/*                                    }}*/}
                            {/*                                ></span>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                        <span className="mb-1">*/}
                            {/*                            {numberWithCurrencyFormat(*/}
                            {/*                            parseFloat(rolloverTarget - rolloverBalance),*/}
                            {/*                            2,*/}
                            {/*                            true*/}
                            {/*                            )}{" "}*/}
                            {/*                            /{" "}*/}
                            {/*                            {numberWithCurrencyFormat(*/}
                            {/*                            parseFloat(rolloverTarget),*/}
                            {/*                            2,*/}
                            {/*                            true*/}
                            {/*                            )}*/}
                            {/*                        </span>*/}
                            {/*                    </div>*/}
                            {/*                )}*/}
                            {/*                { /* MIN COINS IF ANY */}
                            {/*                {isBonusWallet && mincoinTarget !== "" && mincoinTarget > 0 && (*/}
                            {/*                    <div className="rollover-balance-box minimum">*/}
                            {/*                    <div className="d-flex">*/}
                            {/*                        <div*/}
                            {/*                            className="mb-1"*/}
                            {/*                            style={{ margineTop: "30px" }}*/}
                            {/*                        >*/}
                            {/*                            <span className="hbc-label">*/}
                            {/*                            {t("Target")}*/}
                            {/*                            </span>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="progress-bar-box-deposit-promotion">*/}
                            {/*                            <span*/}
                            {/*                            className="progress-identifier"*/}
                            {/*                            style={{*/}
                            {/*                                width:*/}
                            {/*                                mincoinTarget <= walletsDetails.bonusWalletBalance*/}
                            {/*                                    ? "100%"*/}
                            {/*                                    : ((walletsDetails.bonusWalletBalance) /*/}
                            {/*                                        mincoinTarget) **/}
                            {/*                                        100 +*/}
                            {/*                                    "%",*/}
                            {/*                            }}*/}
                            {/*                            ></span>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                        <span className="mb-1">*/}
                            {/*                            {numberWithCurrencyFormat(*/}
                            {/*                            parseFloat(walletsDetails.bonusWalletBalance > mincoinTarget ? mincoinTarget:walletsDetails.bonusWalletBalance),*/}
                            {/*                            2,*/}
                            {/*                            true*/}
                            {/*                            )}{" "}*/}
                            {/*                            /{" "}*/}
                            {/*                            {numberWithCurrencyFormat(*/}
                            {/*                            parseFloat(mincoinTarget),*/}
                            {/*                            2,*/}
                            {/*                            true*/}
                            {/*                            )}*/}
                            {/*                        </span>*/}
                            {/*                    </div>*/}
                            {/*                )}*/}

                            {/*                {isBonusWallet && mincoinTarget !== "" && mincoinTarget == 0 && (*/}
                            {/*                    <div className="rollover-balance-box minimum">*/}
                            {/*                    <div className="d-flex">*/}
                            {/*                        <div*/}
                            {/*                            className="mb-1"*/}
                            {/*                            style={{ margineTop: "30px" }}*/}
                            {/*                        >*/}
                            {/*                            <span className="hbc-label">*/}
                            {/*                            {t("Target")}*/}
                            {/*                            </span>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="progress-bar-box-deposit-promotion">*/}
                            {/*                            <span*/}
                            {/*                            className="progress-identifier"*/}
                            {/*                            style={{*/}
                            {/*                                width:"100%"}}*/}
                            {/*                            ></span>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                        <span className="mb-1">*/}
                            {/*                            {t("NoTarget")}*/}
                            {/*                        </span>*/}
                            {/*                    </div>*/}
                            {/*                )}*/}

                            {/*  { /* Claim/End */}
                            {/*  {isBonusWallet && rolloverBalance !== "" && rolloverTarget !== "" && (<div*/}
                            {/*    className={"hbc-box convert-btn " + (!isBusy && ((rolloverBalance === 0 && ((mincoinTarget > 0 && mincoinTarget <= walletsDetails.bonusWalletBalance || mincoinTarget == 0))) || walletsDetails.bonusWalletBalance < 0.5) ? "" : "unavailable")}*/}
                            {/*    onClick={() => !isBusy && ((rolloverBalance === 0 && ((mincoinTarget > 0 && mincoinTarget <= walletsDetails.bonusWalletBalance || mincoinTarget == 0))) || walletsDetails.bonusWalletBalance < 0.5) && getRestoreAccountBlance()}*/}
                            {/*  >*/}
                            {/*    <div className="convert-btn-cont">*/}
                            {/*        <div className="first">*/}
                            {/*          <div className="hbc-balance font14 f-weight-500" >*/}
                            {/*            {walletsDetails.bonusWalletBalance < 0.5 ? t("END"):numberWithCurrencyFormat(parseFloat(maxWithdraw === 0 ? walletsDetails.bonusWalletBalance : (maxWithdraw >= walletsDetails.bonusWalletBalance ? walletsDetails.bonusWalletBalance : maxWithdraw), 2, true))}*/}
                            {/*          </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="second">*/}
                            {/*        { walletsDetails.bonusWalletBalance < 0.5 ? t("PROMO"):t("CLAIM")}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*  </div>)}*/}


                            {/*</>}*/}
                          </div>

                        </div>

                        {props.selectedGameName == "Pussy888" ||
                          props.selectedGameName == "Mega888" ||
                          props.selectedGameName == "Evo888" ? (
                          <div className="mt-3">
                            <p
                              style={{
                                color: "#ff1a1a",
                                fontSize: "12px",
                                lineHeight: "17px",
                              }}
                            >
                              {t("PRODUCT_ROLLOVER_MESSAGE")}
                            </p>
                          </div>
                        ) : null}

                        <div className="text-center mt-4">
                          {!isApp || (isApp && appDeepLink != "") ? (
                            <>
                              {!toLobbyBtn ? (
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (!window.ReactNativeWebView) {
                                        if (walletsDetails.isValidForPromotion && totalPromoClaimable > 0) {
                                        setPromoAlert(true);
                                        setCountdown(5);
                                        countDownAlert();
                                      } else {
                                        startGame2(props.productGameId)
                                      }
                                    } else {
                                      if (isApp) {
                                        window.ReactNativeWebView.postMessage(
                                          JSON.stringify({
                                            action: "deeplink",
                                            url: appUrl,
                                            deeplink: appDeepLink
                                              .replace(
                                                "[username]",
                                                mpData["username"]
                                              )
                                              .replace(
                                                "[password]",
                                                mpData["password"]
                                              ),
                                          })
                                        );
                                      } else {
                                        if (isNewWindow === true) {
                                          window.ReactNativeWebView.postMessage(
                                            JSON.stringify({
                                              url: `${process.env
                                                .REACT_APP_ENDPOINT_URL ||
                                                window.location.origin
                                                }${ApiUrl._API_START
                                                }?gameId=${props.productGameId}&device=${window.innerWidth <= 900 ? "m" : "d"}`,
                                            })
                                          );
                                        } else {
                                          window.ReactNativeWebView.postMessage(
                                            JSON.stringify({
                                              url: `${process.env
                                                .REACT_APP_ENDPOINT_URL ||
                                                window.location.origin
                                                }${WebUrl._URL_GAME_PAGE
                                                }?gameId=${props.productGameId}`,
                                              selectedTheme:
                                                _USER_DATA.selectedTheme,
                                            })
                                          );
                                        }
                                      }
                                    }
                                  }}
                                  className="t3-custom-light-btn"
                                >
                                  {t("START_GAME")}
                                </button>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                if (window.ReactNativeWebView) {
                                  window.ReactNativeWebView.postMessage(
                                    JSON.stringify({ url: appUrl })
                                  );
                                } else {
                                  window.open(appUrl);
                                }
                              }}
                              className="t3-custom-light-btn"
                            >
                              {t("DOWNLOAD_APP")}
                            </button>
                          )}
                        </div>
                        {showAppCred && (
                          <div className="report-final-balance-box mt-3">
                            <div
                              className="earn-stat-title mt-2"
                              style={{ position: "relative" }}
                            >
                              <div className="details-label">
                                {t("USERNAME") + " : "}
                                <input
                                  className="app-login-cred-text-box ml-2"
                                  type="text"
                                  value={appUsername}
                                  ref={usernameRef}
                                  readOnly
                                />
                              </div>

                              <div
                                onClick={() => copyText("username")}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon
                                  path={mdiContentCopy}
                                  size={1}
                                  color="#cbd5e1"
                                />
                              </div>
                            </div>
                            <div
                              className="earn-stat-title mt-2"
                              style={{ position: "relative" }}
                            >
                              <div className="details-label">
                                {t("PASSWORD") + " : "}
                                <input
                                  className="app-login-cred-text-box ml-2"
                                  type="text"
                                  value={appPassword}
                                  ref={passwordRef}
                                  readOnly
                                />
                              </div>

                              <div
                                onClick={() => copyText("password")}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon
                                  path={mdiContentCopy}
                                  size={1}
                                  color="#cbd5e1"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>)
                }


              </div>
            </div>
          </div>


        </ModalBody>
      </Modal>
    </>
  )

}

export default GameLobbyModal;
