import React, { lazy, Suspense } from "react";
import { Redirect } from "react-router-dom";
import { BaseWebUrl as WebUrl } from "../util/Constant";

// export const _LOGIN_ROUTE2 = {
//     path: WebUrl._URL_MAIN,
//     exact: false,
//     authRequired: false,
//     component: <Redirect to={WebUrl._URL_WELCOME} />,
// };

export async function fetchRoute(themeVar, type) {
    // CHRIS_LOCALE: Redirect if trailing slash detected
    if (window.location.pathname !== null && window.location.pathname !== "/" && window.location.pathname.endsWith("/")) {
        // // CHRIS TODO: Send 301 redirect for SEO
        // Replace isn't ideal inside react router because it will cause rerender
        // window.location.replace(window.location.pathname.slice(0, -1) + window.location.search);

        window.location.href = window.location.pathname.slice(0, -1);

        // <Redirect> doesn't work
        // return <Redirect to={window.location.pathname.slice(0, -1)} />;
    }

    // Use the variable to fetch data or perform some logic
    // (`Fetching data for variable: ${themeVar}`);

    // const Welcome = lazy(async () => await import(`pages/${themeVar}/Welcome`));
    const Login = lazy(async () => await import(`pages/${themeVar}/Login`));
    const LiveChat = lazy(async () => await import(`pages/${themeVar}/LiveChat`));
    // const Register = lazy(async () => await import(`pages/${themeVar}/Register`));
    // const Forgot = lazy(async () => await import(`pages/${themeVar}/Forgot`));
    // const Dashboard = lazy(
    //     async () => await import(`pages/${themeVar}/home/Dashboard`)
    // );
    // const Transaction = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/Transaction`)
    // );
    // const TransferSuccessful = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/TransferSuccessful`)
    // );
    // const Withdrawal = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/WithdrawalMobile`)
    // );
    // const WithdrawalRecord = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/WithdrawalRecord`)
    // );
    // const Deposit = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/Deposit`)
    // );
    // const DepositRecord = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/DepositRecord`)
    // );
    // const Downline = lazy(
    //     async () => await import(`pages/${themeVar}/home/Downline`)
    // );
    // const MyDownline = lazy(
    //     async () => await import(`pages/${themeVar}/home/MyDownline`)
    // );
    // const DownlineDetails = lazy(
    //     async () => await import(`pages/${themeVar}/home/DownlineDetails`)
    // );
    // const EditDownline = lazy(
    //     async () => await import(`pages/${themeVar}/home/EditDownline`)
    // );
    const Settings = lazy(() => import(`pages/${themeVar}/home/Settings`));
    const Kpi = lazy(async () => await import(`pages/${themeVar}/home/Kpi`));
    const News = lazy(async () => await import(`pages/${themeVar}/home/News`));
    const NewsDetails = lazy(
        async () => await import(`pages/${themeVar}/home/NewsDetails`)
    );

    // const PoolResult = lazy(
    //     async () => await import(`pages/${themeVar}/game/PoolResult`)
    // );
    const GameLobby = lazy(
        async () => await import(`pages/${themeVar}/game/GameLobby`)
    );
    const UnderMaintenance = lazy(
        async () => await import(`pages/${themeVar}/game/UnderMaintenance`)
    );

    // const ReportMenu = lazy(
    //     async () => await import(`pages/${themeVar}/report/ReportMenu`)
    // );
    // const WalletHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/WalletHistory`)
    // );
    // const WalletHistoryMobile = lazy(
    //     async () => await import(`pages/${themeVar}/report/WalletHistoryMobile`)
    // );
    // const CommissionHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/CommissionHistory`)
    // );
    // const BonusHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/BonusHistory`)
    // );
    // const InternaTransferHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/InternaTransferHistory`)
    // );
    // const RolloverStatement = lazy(
    //     async () => await import(`pages/${themeVar}/report/RolloverStatement`)
    // );
    // const TransactionHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/TransactionHistory`)
    // );
    // const GameHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/GameHistory`)
    // );
    const GameDetails = lazy(
        async () => await import(`pages/${themeVar}/report/GameDetails`)
    );
    // const MembersTransfer = lazy(
    //     async () => await import(`pages/${themeVar}/report/MembersTransfer`)
    // );
    // const GameWalletTransfer = lazy(
    //     async () => await import(`pages/${themeVar}/report/GameWalletTransfer`)
    // );
    // const ReportsOverview = lazy(
    //     async () => await import(`pages/${themeVar}/report/ReportsOverview`)
    // );
    // const ForgetPin = lazy(
    //     async () => await import(`pages/${themeVar}/member/ForgetPin`)
    // );

    // const DownlineGroupDetail = lazy(
    //     async () => await import(`pages/${themeVar}/member/DownlineGroupDetail`)
    // );

    // const UnclaimCommissionHistory = lazy(
    //     async () =>
    //         await import(`pages/${themeVar}/member/UnclaimCommissionHistory`)
    // );

    // const Maintenance = lazy(
    //     async () => await import(`pages/${themeVar}/Maintenance`)
    // );
    const ContactUs = lazy(
        async () => await import(`pages/${themeVar}/home/ContactForm`)
    );
    const LiveChatPage = lazy(
        async () => await import(`pages/${themeVar}/home/LiveChat`)
    );

    // const Shareholder = lazy(
    //     async () => await import(`pages/${themeVar}/shareholder/Shareholder`)
    // );
    // const ShareholderDetails = lazy(
    //     async () => await import(`pages/${themeVar}/shareholder/ShareholderDetails`)
    // );
    // const EditShareholder = lazy(
    //     async () => await import(`pages/${themeVar}/shareholder/EditShareholder`)
    // );
    // const RewardsTnC = lazy(
    //     async () => await import(`pages/${themeVar}/home/RewardsTnC`)
    // );
    // const ClaimRecord = lazy(
    //     async () => await import(`pages/${themeVar}/shareholder/ClaimRecord`)
    // );
    // const SummaryDownlineSales = lazy(
    //     async () =>
    //         await import(`pages/${themeVar}/shareholder/SummaryDownlineSales`)
    // );
    // const DownlineProductRateDetail = lazy(
    //     async () =>
    //         await import(`pages/${themeVar}/member/DownlineProductRateDetail`)
    // );

    // const Rewards = lazy(
    //     async () => await import(`pages/${themeVar}/home/Rewards`)
    // );
    // const RewardsHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/RewardsHistory`)
    // );
    // const RewardsDetail = lazy(
    //     async () => await import(`pages/${themeVar}/report/RewardsDetail`)
    // );
    // const GamePage = lazy(
    //     async () => await import(`pages/${themeVar}/home/GamePage`)
    // );
    // const WalletTransferHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/WalletTransferHistory`)
    // );
    // const WalletStatement = lazy(
    //     async () => await import(`pages/${themeVar}/report/WalletStatement`)
    // );

    // const TouchnGO = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/ReloadTnG`)
    // );
    // const ReloadBank = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/ReloadBank`)
    // );
    // const UploadReloadReceipt = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/UploadReloadReceipt`)
    // );
    // const ReloadDone = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/ReloadDone`)
    // );
    // const MainWallet = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/MainWallet`)
    // );
    // const GameReload = lazy(
    //     async () => await import(`pages/${themeVar}/game/GameReload`)
    // );
    // const WithdrawalMethods = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/WithdrawalMethods`)
    // );
    // const ScanQR = lazy(async () => await import(`pages/${themeVar}/qr/ScanQR`));
    // const CreateAccount = lazy(
    //     async () => await import(`pages/${themeVar}/qr/CreateAccount`)
    // );
    // const VerifyCreateAccount = lazy(
    //     async () => await import(`pages/${themeVar}/qr/Verify`)
    // );
    // const CreatePassword = lazy(
    //     async () => await import(`pages/${themeVar}/qr/CreatePassword`)
    // );
    // const CreateAccountSuccess = lazy(
    //     async () => await import(`pages/${themeVar}/qr/Success`)
    // );
    // const CreateAccountByReferralCode = lazy(
    //     async () => await import(`pages/${themeVar}/qr/Referral`)
    // );
    // const WithdrawalInProgress = lazy(
    //     async () =>
    //         await import(`pages/${themeVar}/transaction/WithdrawalInProgress`)
    // );
    // const Commission = lazy(
    //     async () => await import(`pages/${themeVar}/home/Commission`)
    // );
    const Transfer = lazy(
        async () => await import(`pages/${themeVar}/transaction/Transfer`)
    );
    // const VipBenefits = lazy(
    //     async () => await import(`pages/${themeVar}/home/VipBenefits`)
    // );
    const Promotion = lazy(
        async () => await import(`pages/${themeVar}/home/Promotion`)
    );
    const PromotionInfo = lazy(
        async () => await import(`pages/${themeVar}/home/PromotionInfo`)
    );
    const CustomPage = lazy(
        async () => await import(`pages/${themeVar}/home/CustomPage`)
    );
    // const Home = lazy(async () => await import(`pages/${themeVar}/home/Home`));
    // const Giftpace = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/Giftpace`)
    // );
    // const CommissionsInfo = lazy(
    //     async () => await import(`pages/${themeVar}/home/CommissionsInfo`)
    // );
    // const Topkash = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/Topkash`)
    // );
    // const DailyCheckIn = lazy(
    //     async () => await import(`pages/${themeVar}/home/DailyCheckIn`)
    // );
    // const DCRedemption = lazy(
    //     async () => await import(`pages/${themeVar}/home/DCRedemption`)
    // );
    // const DCRedemptionHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/DCRedemptionHistory`)
    // );
    // const UnclaimDCPoints = lazy(
    //     async () => await import(`pages/${themeVar}/member/UnclaimDCPoints`)
    // );
    // const DCPointsHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/DCPointsHistory`)
    // );
    // const VipCommissionHistory = lazy(
    //     async () => await import(`pages/${themeVar}/report/VipCommissionHistory`)
    // );

    // const HuiOneDeposit = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/HuiOneDeposit`)
    // );
    // const CryptoWalletsDeposit = lazy(
    //     async () =>
    //         await import(`pages/${themeVar}/transaction/CryptoWalletsDeposit`)
    // );

    // const CryptoDeposit = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/CryptoDeposit`)
    // );

    ///template 3
    const GameList = lazy(
        async () => await import(`pages/${themeVar}/game/GameList`)
    );
    const Referral = lazy(async () => await import(`pages/${themeVar}/home/Referral.js`));
    const Vip = lazy(async () => await import(`pages/${themeVar}/home/Vip.js`));
    const Rebate = lazy(async () => await import(`pages/${themeVar}/home/Rebate.js`));
    
    const RecentGames = lazy(async () => await import(`pages/${themeVar}/game/RecentGames.js`));
    const NotFound = lazy(async () => await import(`pages/${themeVar}/NotFound.js`));
    // const WingWeiluyDeposit = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/WingWeiluyDeposit`)
    // );
    // const UploadWingWeiluyReceipt = lazy(
    //     async () => await import(`pages/${themeVar}/transaction/UploadWingWeiluyReceipt`)
    // );

    // Add your data fetching logic here

    // const _LOGIN_ROUTE = {
    //     path: WebUrl._URL_MAIN,
    //     exact: false,
    //     authRequired: false,
    //     component: <Redirect to={WebUrl._URL_WELCOME} />,
    // };
    const IframeGamePage = lazy(
        async () => await import(`pages/${themeVar}/game/GamePage2`)
    );

    const _LOGIN_ROUTE3 = [{
        // path: WebUrl._URL_MAIN,
        // exact: false,
        // authRequired: false,
        // component: <Redirect to={WebUrl._URL_HOME} />,
        path: WebUrl._URL_HOME,
        exact: true,
        title: "Home",
        component: <Login />,
    }];

    const _ROUTES = [
        {
            path: WebUrl._URL_NOT_FOUND,
            exact: true,
            title: "404 Not Found",
            authRequired: false,
            component: <NotFound />,
        },
        {
            path: WebUrl._URL_LIVECHAT,
            exact: true,
            title: "LiveChat",
            authRequired: false,
            component: <LiveChat />,
        },
        {
            path: WebUrl._URL_UNDER_MAINTENANCE,
            exact: true,
            title: "Under Maintenance",
            authRequired: false,
            component: <UnderMaintenance />,
        },
        {
            path: WebUrl._URL_SETTINGS,
            exact: true,
            title: "Settings",
            authRequired: true,
            component: <Settings />,
        },
        {
            path: WebUrl._URL_REGISTER,
            exact: true,
            title: "Register",
            authRequired: false,
            component: <Login />,
        },
        {
            path: WebUrl._URL_GAME_LOBBY,
            exact: true,
            title: "Game Lobby",
            authRequired: false,
            component: <GameLobby />,
        },
        {
            path: WebUrl._URL_NEWS,
            exact: true,
            title: "News",
            authRequired: true,
            component: <News />,
        },
        {
            path: WebUrl._URL_NEWS_DETAILS,
            exact: true,
            title: "Detail",
            authRequired: true,
            component: <NewsDetails />,
        },
        {
            path: WebUrl._URL_CONTACT_US,
            exact: true,
            title: "Contact Us",
            component: <ContactUs />,
        },
        {
            path: WebUrl._URL_LIVE_CHAT,
            exact: true,
            title: "Live Chat",
            component: <LiveChatPage />,
        },
        // {
        //     path: WebUrl._URL_GAME_PAGE,
        //     exact: true,
        //     title: "Game",
        //     authRequired: true,
        //     component: <GamePage />,
        // },
        {
            path: WebUrl._URL_PROMOTION,
            exact: true,
            title: "Promotion",
            authRequired: false,
            component: <Promotion />,
        },
        {
            path: WebUrl._URL_PROMOTION_INFO,
            exact: true,
            title: "Promotion Information",
            authRequired: false,
            component: <PromotionInfo />,
        },
        {
            path: WebUrl._URL_HOME,
            exact: true,
            title: "Home",
            component: <Login />,
        },
        {
            path: WebUrl._URL_GAME_LIST,
            exact: true,
            title: "Game List",
            authRequired: false,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_REFERRAL,
            exact: true,
            title: "Referral",
            authRequired: false,
            component: <Referral />,
        },
        {
            path: WebUrl._URL_VIP,
            exact: true,
            title: "Vip",
            authRequired: false,
            component: <Vip />,
        },
        {
            path: WebUrl._URL_REBATE,
            exact: true,
            title: "Rebate",
            authRequired: false,
            component: <Rebate />,
        },
        {
            path: WebUrl._URL_RECENT_GAMES,
            exact: true,
            title: "Recent Games",
            authRequired: true,
            component: <RecentGames />,
        },
        {
            path: WebUrl._URL_GAME_LIST_SPORTS,
            exact: true,
            title: "Sport",
            authRequired: false,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_GAME_LIST_SLOTS,
            exact: true,
            title: "Slots",
            authRequired: false,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_GAME_LIST_CASINO,
            exact: true,
            title: "Casino",
            authRequired: false,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_GAME_LIST_FISHHUNT,
            exact: true,
            title: "Fish Hunt",
            authRequired: false,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_GAME_LIST_KENO,
            exact: true,
            title: "Keno",
            authRequired: false,
            component: <GameList />,
        },
        {
            path: "/promo/:slug",
            exact: false,
            title: "Promotion Information",
            authRequired: false,
            component: <PromotionInfo />,
        },
        {
            path: "/pages/:slug",
            exact: false,
            title: "Custom Pages",
            authRequired: false,
            component: <CustomPage />,
        },
        {
            path: WebUrl._URL_GAME_LIST_E_SPORT,
            exact: true,
            title: "E-Sport",
            authRequired: false,
            component: <GameList />,
        },
        {
            path: WebUrl._URL_IFRAME_GAME_PAGE,
            exact: true,
            title: "Game Page",
            authRequired: true,
            component: <IframeGamePage />,
        },
    ];

    // const createRoutesWithTrailingSlashRedirects = (routes) => {
    //     return routes.reduce((acc, route) => {
    //         const { path, title, exact, authRequired, component } = route;

    //         // Skip wildcard path '*' since it should not have a trailing slash redirect
    //         if (path === '*') {
    //             return acc.concat(route);
    //         }

    //         const routesWithRedirect = [
    //             {
    //                 path: `${path}/`,
    //                 exact: true,
    //                 title: title,
    //                 authRequired: false, // No auth required for redirects
    //                 component: <Redirect to={path} />,
    //             },
    //             route,
    //         ];

    //         return acc.concat(routesWithRedirect);
    //     }, []);
    // };

    // const locales = ['en', 'bn']; // Example locales

    // const generateLocaleRoutes = (routes) => {
    //     // return locales.flatMap(locale =>
    //     //     routes.map(route => ({
    //     //         ...route,
    //     //         path: `/${locale}${route.path}`,
    //     //     }))
    //     // );
    //     return routes
    // };

    // Do not need this anymore - better to do <Redirect> directly
    // const _SANITISED_ROUTES = createRoutesWithTrailingSlashRedirects(generateLocaleRoutes(_ROUTES));

    switch (type) {
        case "login":
            return ConvertToLocalePath(_LOGIN_ROUTE3);
            // if (themeVar == "template_1") return _LOGIN_ROUTE3;
            // else return _LOGIN_ROUTE;
            break;
        default:
            // return _ROUTES;
            return ConvertToLocalePath(_ROUTES);
            break;
    }
}

function ConvertToLocalePath(routes) {
    // CHRIS_LOCALE: Prepend locale to each path
    // Add :locale to all route paths to accommodate for example.com/:locale/pathname

    let _LOCALED_ROUTES = [];
    try {
        _LOCALED_ROUTES = routes.map((route) => {
            const { path } = route;
            const localePath = path.startsWith('/') ? `/:locale${path}` : `:locale${path}`;
            return {
                ...route,
                path: localePath,
            }
        });
    } catch (error) {
        console.error(error)
        throw error
    }

    return _LOCALED_ROUTES;
}